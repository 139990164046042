import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HomeService } from 'app/home/home.service';

@Injectable()
export class ParameterService extends HomeService {

  public msg: any;

  constructor(public http: HttpClient) {
    super(http, 'parameter');
    this.loadMessage();
  }

  private loadMessage() {
    this.msg = {
      '400.20': 'Dados inválidos.',
      '400.21': 'Identificador é obrigatório.',
      '400.22': 'Identificador é inválido.',
      '400.23': 'Valor do parâmetro é obrigatório é inválido.',
      '400.24': 'Esse parâmetro aceita apenas números.'
    }
  }
}
