import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NgxMaskModule, IConfig } from 'ngx-mask';
import { ImageCropperModule } from 'ngx-image-cropper';

import { ViacepService } from './service/viacep.service';

import { ConsumerService } from './service/consumer.service';
import { ConsumerComponent } from './consumer.component';
import { Consumer } from './model/consumer.model';


export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

@NgModule({
    declarations: [
        ConsumerComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NgxMaskModule.forRoot(),
        ImageCropperModule
    ],
    providers: [Consumer, ConsumerService, ViacepService]
})
export class ConsumerModule { }
