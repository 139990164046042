export class Address {

    id: string;
    address: string;
    number: string;
    complement: string;
    neighborhood: string;
    city: string;
    state: string;
    zipcode: string;

    loadCep(data) {
        this.address = data.logradouro;
       // this.complement = data.complemento;
        this.neighborhood = data.bairro;
        this.city = data.localidade;
        this.state = data.uf;

        if (data.cep) {
            this.zipcode = data.cep;
        }
    }

    load(data) {
        this.id = data.id;
        this.address = data.address;
        this.complement = data.complement;
        this.number = data.number;
        this.neighborhood = data.neighborhood;
        this.city = data.city;
        this.state = data.state;
        this.zipcode = data.zipcode;
    }

    constructor() {
    }
}
