import { Routes } from '@angular/router';

export const AppRoutes: Routes = [{
  path: '',
  redirectTo: 'auth',
  pathMatch: 'full',
},
{
  path: 'auth',
  children: [{
    path: '',
    loadChildren: './auth/auth.module#AuthModule'
  }]
},
{
  path: 'home',
  children: [{
    path: '',
    loadChildren: './home/home.module#HomeModule'
  }]
}];
