import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { Home } from '../Home';
import { Filter } from './model/filter.model';
import { ReportService } from './service/report.service';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.css']
})
export class ReportComponent extends Home implements OnInit {

  months: any;
  states: any;
  neighborhoods: any;
  citys: any;
  genres: any;
  ages: any;
  status: any;
  types: any;

  rclassifications: any;
  dclassifications: any;
  cclassifications: any;

  lista: any;
  step: number;
  filters: any;

  constructor(private router: Router, public model: Filter, private reportService: ReportService) {
    super(router);
  }

  ngOnInit(): void {
    this.load();
    this.clear();
    this.nextStep(1);
    this.filters = 0;
  }

  nextStep(n) {
    this.step = n;
  }

  doReport() {
    if (!this.model.name && this.model.save) { return; }

    if (this.model.result === 'GRID') {
      this.retGrid();
    }

    if (this.model.result === 'EXCEL') {
      this.retExcel();
    }

    if (this.model.result === 'MAPA') {
      this.retMap();
    }

    if (this.model.result === 'LABEL') {
      this.retLabel();
    }
  }

  retGrid() {
    this.calculeFilters();

    const socket = this.reportService.post(this.model)
      .subscribe(succ => {
        const ret: any = succ;
        this.lista = ret;
      }, err => {

      }, () => {
        socket.unsubscribe();
      });
  }

  retExcel() {
    const socket = this.reportService.download(this.model, 'excel')
      .subscribe(succ => {
        const resp: any = succ;

        let name = this.model.name && this.model.name.length ? this.model.name.replace(/\s/g, '').toLocaleLowerCase() + '_' : 'relatorio_';
        name = 'excel_' + name + Date.now() + '.xlsx';

        const a = document.createElement('a');
        const blob = new Blob([resp]);

        a.href = URL.createObjectURL(blob);
        a.download = name;
        a.target = '_blank';
        a.click();

        a.remove();
      }, err => {
        console.log('err', err);
      }, () => {
        socket.unsubscribe();
      });
  }

  retLabel() {
    const socket = this.reportService.download(this.model, 'label')
      .subscribe(succ => {
        const resp: any = succ;

        let name = this.model.name && this.model.name.length ? this.model.name.replace(/\s/g, '').toLocaleLowerCase() + '_' : 'relatorio_';
        name = 'label_' + name + Date.now() + '.pdf';

        const a = document.createElement('a');
        const blob = new Blob([resp]);

        a.href = URL.createObjectURL(blob);
        a.download = name;
        a.target = '_blank';
        a.click();

        a.remove();
      }, err => {
        console.log('err', err);
      }, () => {
        socket.unsubscribe();
      });
  }

  retMap() {
    const socket = this.reportService.custompost('report/map', this.model)
      .subscribe(succ => {
        const ret: any = succ;

        const id = Date.now().toString(24);
        sessionStorage.setItem(id, JSON.stringify(ret));

        this.go('./home/maps', id);

      }, err => {

      }, () => {
        socket.unsubscribe();
      });
  }

  clear() {
    this.model = new Filter();
  }

  load() {
    this.loadMonths();
    this.loadGenres();
    this.loadStates();
    this.loadNeighborhoods();
    this.loadCitys();
    this.loadAges();
    this.loadTypes();
    this.loadRClassification();
    this.loadDClassification();
    this.loadCClassification();
    this.loadRequest();
  }

  loadMonths() {
    this.months = [
      { id: '01', name: 'Janeiro' },
      { id: '02', name: 'Fevereiro' },
      { id: '03', name: 'Março' },
      { id: '04', name: 'Abril' },
      { id: '05', name: 'Maio' },
      { id: '06', name: 'Junho' },
      { id: '07', name: 'Julho' },
      { id: '08', name: 'Agosto' },
      { id: '09', name: 'Setembro' },
      { id: '10', name: 'Outubro' },
      { id: '11', name: 'Novembro' },
      { id: '12', name: 'Dezembro' }];
  }

  loadTypes() {
    this.types = [{ id: 'C', name: 'Contrato' }, { id: 'A', name: 'Aditivo' }];
  }

  loadGenres() {
    this.genres = [{ id: 'M', name: 'Masculino' }, { id: 'F', name: 'Feminino' }];
  }

  loadStates() {
    this.states = [];
    const socket = this.reportService.dropdown('consumer/state')
      .subscribe(succ => {
        const ret: any = succ;
        this.states = ret;
      }, err => {
        console.log('err', err);
      }, () => {
        socket.unsubscribe();
      });
  }

  loadNeighborhoods() {
    this.neighborhoods = [];
    const socket = this.reportService.dropdown('consumer/neighborhood')
      .subscribe(succ => {
        const ret: any = succ;
        this.neighborhoods = ret;
      }, err => {
        console.log('err', err);
      }, () => {
        socket.unsubscribe();
      });
  }

  loadCitys() {
    this.citys = [];
    const socket = this.reportService.dropdown('consumer/city')
      .subscribe(succ => {
        const ret: any = succ;
        this.citys = ret;
      }, err => {
        console.log('err', err);
      }, () => {
        socket.unsubscribe();
      });
  }

  loadRequest() {
    this.status = [];
    const socket = this.reportService.dropdown('request/status')
      .subscribe(succ => {
        const ret: any = succ;
        this.status = this.toStatus(ret);
      }, err => {
        console.log('err', err);
      }, () => {
        socket.unsubscribe();
      });
  }

  loadRClassification() {
    const socket = this.reportService.dropdown('classification/Ocorrência')
      .subscribe(succ => {
        const ret: any = succ;
        this.rclassifications = ret;
      }, err => {
        console.log('err', err);
        this.allowAcess(err.status);
      }, () => {
        socket.unsubscribe();
      });
  }

  loadCClassification() {
    const socket = this.reportService.dropdown('classification/Contrato')
      .subscribe(succ => {
        const ret: any = succ;
        this.cclassifications = ret;
      }, err => {
        console.log('err', err);
        this.allowAcess(err.status);
      }, () => {
        socket.unsubscribe();
      });
  }

  loadDClassification() {
    const socket = this.reportService.dropdown('classification/Cliente')
      .subscribe(succ => {
        const ret: any = succ;

        this.dclassifications = ret;
      }, err => {
        console.log('err', err);
        this.allowAcess(err.status);
      }, () => {
        socket.unsubscribe();
      });
  }

  loadAges() {
    this.ages = [];
    for (let i = 1; i <= 105; i++) {
      this.ages.push(i);
    }
  }

  toStatus(lst) {
    const ret = [];

    lst.forEach(s => {
      ret.push({
        id: s, name: s === 'AB' ? 'ABERTO' :
          s === 'AN' ? 'ANDAMENTO' :
            s === 'FN' ? 'FINALIZADO NÃO ATENDIDO' : 'FINALIZADO ATENDIDO'
      })
    });
    return ret;
  }

  calculeFilters() {
    this.filters = 1;

    Object.keys(this.model.consumer).forEach(e => {
      if (this.model.consumer[e].length) {
        this.filters = this.filters + 1;
      }
    });

    Object.keys(this.model.address).forEach(e => {
      if (this.model.address[e].length) {
        this.filters = this.filters + 1;
      }
    });

    Object.keys(this.model.request).forEach(e => {
      if (this.model.request[e].length) {
        this.filters = this.filters + 1;
      }
    });

    Object.keys(this.model.contract).forEach(e => {
      if (this.model.contract[e].length) {
        this.filters = this.filters + 1;
      }
    });
  }
}
