import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { ForgotService } from './service/forgot.service';

@Component({
  selector: 'app-forgot',
  templateUrl: './forgot.component.html',
  styleUrls: ['./forgot.component.scss']
})
export class ForgotComponent implements OnInit {

  success: boolean;
  msg: string;
  btnActive: boolean
  mail: any;

  constructor(private router: Router, private forgotService: ForgotService) {
    this.btnActive = false;
    this.success = false;
  }

  ngOnInit() {
  }

  doForgot(model, valid) {

    if (!valid) { return; }
    this.btnActive = !this.btnActive;

    const socket = this.forgotService
      .getId(model.email)
      .subscribe(succ => {
        this.msg = 'Caso o e-mail esteja correto, uma nova senha foi enviada para sua caixa de mensagem. Por favor verifique.';
        this.success = true;

      }, err => {
        this.btnActive = !this.btnActive;
        if (err.status === 400) {
          this.msg = this.forgotService.msg[err.error.code];
          return;
        }
        this.msg = 'Não foi possível realizar a operação';
        console.log('err', err);
      }, () => {
        socket.unsubscribe();
      });
  }

  public goLogin() {
    this.router.navigate(['/auth']);
  }
}
