import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { DatePipe } from '@angular/common';

import { NavbarModule } from './shared/navbar/navbar.module';
import { FooterModule } from './shared/footer/footer.module';
import { SidebarModule } from './sidebar/sidebar.module';
import { ProfileModule } from './profile/profile.module';
import { ClassificationModule } from './classification/classification.module';
import { UserModule } from './user/user.model';
import { ParameterModule } from './parameter/parameter.module';
import { ConsumerModule } from './consumer/consumer.module';
import { ConsumerRequestModule } from './consumer-request/consumer-request.module';
import { SearchModule } from './search/search.modules';
import { RequestModule } from './request/request.module';

import { HomeComponent } from './home.component';
import { HomeRoutes } from './home.routing';

import { ReportModule } from './report/report.module';
import { MapModule } from './maps/maps.module';
import { DashboardModule } from './dashboard/dashboard.modules';
import { ReportFixedModule } from './report-fixed/report-fixed.module';
import { CalendarSharedModule } from './shared/calendar/calendar-shared.module';
import { ConsumerContractModule } from './consumer-contract/consumer-contract.module';
import { ContractModule } from './contract/contract.module';

@NgModule({
    declarations: [
        HomeComponent
    ],
    imports: [
        RouterModule.forChild(HomeRoutes),
        NavbarModule,
        FooterModule,
        SidebarModule,
        ProfileModule,
        ClassificationModule,
        UserModule,
        ParameterModule,
        ConsumerModule,
        ConsumerRequestModule,
        SearchModule,
        RequestModule,
        ReportModule,
        MapModule,
        DashboardModule,
        ReportFixedModule,
        CalendarSharedModule,
        ConsumerContractModule,
        ContractModule
    ],
    providers: [DatePipe]
})
export class HomeModule { }
