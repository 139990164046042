import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NgxMaskModule } from 'ngx-mask';
import { SearchComponent } from './search.component';
import { Filter } from './model/filter.model';
import { SearchService } from './service/search.service';


@NgModule({
    declarations: [
        SearchComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NgxMaskModule.forRoot()
    ],
    providers: [Filter, SearchService]
})
export class SearchModule { }
