export class Solicitation {

    id: string;
    protocol: string;
    description: string;
    classifications: any;
    updated: string;
    status: string;
    consumerid: string;

    constructor() {
        this.protocol = this.generate();
        this.status = 'AB';
    }

    load(model) {
        this.id = model.id;
        this.protocol = model.protocol;
        this.description = model.description;
        this.status = model.status;
        this.consumerid = model.consumerid;
        this.classifications = [];
        model.classifications.forEach(e => {
            this.classifications.push(e.id);
        });
    }

    generate() {

        const start = new Date((new Date()).getFullYear(), 0, 0);
        let day = 0;

        while (start.toLocaleDateString('pt-BR') !== (new Date()).toLocaleDateString('pt-BR')) {
            start.setDate(start.getDate() + 1);
            day++;
        }

        const tm = (new Date()).getFullYear().toString().substr(2)
        const dy = day.toString().padStart(5, '0').substr(-3);
        const hr = (new Date()).toLocaleTimeString().replace(/\:/gi, '');

        return tm + dy + hr;
    }
}
