export class AddressFilter {

    address: string;
    number: string;
    complement: string;
    neighborhood: string;
    city: string;
    state: string;
    zipcode: string;

    constructor() {
    }
}
