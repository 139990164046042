export class Contract {
    public id: string;
    public number: string;
    public description: boolean;
    public consumerid: string;
    public source: string;
    public type: string;
    public initial: string;
    public final: string;
    public classifications: any;

    constructor() {
    }

    load(data) {
        this.id = data.id;
        this.number = data.number;
        this.description = data.description;
        this.consumerid = data.consumerid;
        this.source = data.source;
        this.type = data.type;
        this.initial = data.initial;
        this.final = data.final;
        this.classifications = [];
        data.classifications.forEach(e => {
            this.classifications.push(e.id);
        });
    }
}
