import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Home } from '../Home';
import { ConsumerRequest } from './model/consumer-request.model';
import { Solicitation } from './model/solicitation.model';
import { ConsumerRequestService } from './service/consumer-request.service';

@Component({
  selector: 'app-consumer-request',
  templateUrl: './consumer-request.component.html',
  styleUrls: ['./consumer-request.component.css']
})
export class ConsumerRequestComponent extends Home implements OnInit {

  lastupdated: any;
  classifications: any;
  age: any;
  rmodal: boolean;
  btn: boolean;

  constructor(private router: Router, private activated: ActivatedRoute, public model: Solicitation,
    public consumer: ConsumerRequest, private consumerRequestService: ConsumerRequestService) {
    super(router);
    this.rmodal = false;
  }

  ngOnInit(): void {
    this.consumer = new ConsumerRequest();
    if (this.activated.snapshot.params.id) {
      this.load(this.activated.snapshot.params.id);
    }
    this.loadClassification();
  }

  load(id) {

    const socket = this.consumerRequestService.getId(id + '/consumer')
      .subscribe(succ => {
        const ret: any = succ;
        this.consumer.load(ret);
        this.lastupdated = ret.updated;
        this.birthToage();
      }, err => {
        this.allowAcess(err.status);
        this.popup(this.consumerRequestService.msg[err.error.code], 'danger');
        console.log('err', err);
      }, () => {
        socket.unsubscribe();
      });
  }

  birthToage() {
    this.age = this.consumer.age();
  }

  myprofile(id) {
    if (!id) { return; }
    this.go('./home/consumer', id);
  }

  mycontract(id) {
    if (!id) { return; }
    this.go('./home/consumer-contract', id);
  }

  visiblemodal() {
    this.rmodal = !this.rmodal;
    this.model = new Solicitation();
  }

  doRequest(model, valid) {

    if (!valid || this.btn) { return; }
    model.consumerid = this.consumer.id;
    this.btn = !this.btn;

    if (!model.id) {
      this.doInsert(model);
    } else {
      this.doUpdate(model);
    }
  }

  doInsert(model) {
    const socket = this.consumerRequestService.post(model)
      .subscribe(succ => {
        const ret: any = succ;
        this.popup('Ocorrência incluída com sucesso!');
        this.visiblemodal();
        this.load(this.consumer.id);
        this.btn = !this.btn;
      }, err => {
        this.allowAcess(err.status);
        this.popup(this.consumerRequestService.msg[err.error.code], 'danger');
        console.log('err', err);
        this.btn = !this.btn;
      }, () => {
        socket.unsubscribe();
      });
  }

  doUpdate(model) {
    const socket = this.consumerRequestService.patch(model.id, model)
      .subscribe(succ => {
        const ret: any = succ;
        this.popup('Ocorrência atualizada com sucesso!');
        this.visiblemodal();
        this.load(this.consumer.id);
        this.btn = !this.btn;
      }, err => {
        this.allowAcess(err.status);
        this.popup(this.consumerRequestService.msg[err.error.code], 'danger');
        console.log('err', err);
        this.btn = !this.btn;
      }, () => {
        socket.unsubscribe();
      });
  }

  loadClassification() {
    const socket = this.consumerRequestService.dropdown('classification/Ocorrência')
      .subscribe(succ => {
        const ret: any = succ;
        this.classifications = ret;
      }, err => {
        console.log('err', err);
        this.allowAcess(err.status);
      }, () => {
        socket.unsubscribe();
      });
  }

  open(model) {
    this.visiblemodal();
    this.model.load(model);
  }
}
