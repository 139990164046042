import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { ImageCroppedEvent } from 'ngx-image-cropper';

import { Home } from '../Home';
import { MeService } from '../sidebar/service/me.service';
import { Change } from './model/change.model';

import { Profile } from './model/profile.model';
import { ProfileService } from './service/profile.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent extends Home implements OnInit {

  imgChangeEvt: any;
  cropImgPreview: any;

  pmodal: boolean;
  panel: boolean;
  currentMail: string;
  original: any;

  constructor(private router: Router, private profileService: ProfileService,
    private meService: MeService, public model: Profile, public change: Change) {
    super(router);
    this.pmodal = false;
    this.panel = false;
  }

  ngOnInit(): void {
    this.load();
    this.cropImgPreview = 'assets/img/default-avatar.png';
  }

  visiblemodal() {
    this.pmodal = !this.pmodal;
    this.imgChangeEvt = undefined;
  }

  load() {
    const socket = this.profileService.get()
      .subscribe(succ => {
        const ret: any = succ;
        this.model = this.toConvert(ret);
        this.original = {...this.model};
      },
        err => {
          if (err.status === 404) { return; }
          console.log('err', err);
          this.allowAcess(err.status);
        }, () => {
          socket.unsubscribe();
        });
  }

  doUpdate(model, valid) {
    if (!valid) { return; }
    model = this.onlyChange(model);
    const socket = this.profileService.patch(undefined, model)
      .subscribe(succ => {
        this.popup('Atualizado');
        this.currentMail = this.model.email;
        this.original = {...this.model};
        this.meService.updated(true);
      },
        err => {
          console.log(err);
          this.allowAcess(err.status);

          if (err.status === 413) {
            this.popup('A imagem é maior que 2MB.', 'danger');
            return;
          }

          this.popup(this.profileService.msg[err.error.code], 'danger');
        }, () => {
          socket.unsubscribe();
        });
  }

  onlyChange(model) {
    Object.keys(this.original).forEach(p => {
      console.log('model ', p, model[p], this.original[p]);
      if (model[p] === this.original[p] && p !== 'name' && p !== 'token') {
        delete model[p];
      }
    });

    return model;
  }

  doChange(model, valid) {

    if (!valid) { return; }

    const send = {
      email: this.currentMail,
      old: model.old,
      password: model.password
    };

    const socket = this.profileService.custompost('auth/change', send)
      .subscribe(succ => {
        this.popup('Senha atualizada');
        this.change = new Change();
        this.allowAcess(401);
      },
        err => {
          this.change = new Change();
          console.log(err);
          this.allowAcess(err.status);

          this.popup(this.profileService.msg[err.error.code], 'danger');
        }, () => {
          socket.unsubscribe();
        });
  }

  toConvert(data) {

    this.currentMail = data.email;

    if (!data.photo) {
      data.photo = 'assets/img/default-avatar.png';
    }

    return data;
  }

  visible() {
    this.change = new Change();
    this.panel = !this.panel;
  }

  onFileChange(event: any): void {
    this.imgChangeEvt = event;
  }

  cropImg(e: ImageCroppedEvent) {
    this.cropImgPreview = e.base64;
  }

  doCrop() {
    this.visiblemodal();
    this.model.photo = this.cropImgPreview;
  }

  imgLoad() {
    this.pmodal = !this.pmodal;
  }
}
