import { AddressFilter } from './address.filter';
import { ConsumerFilter } from './consumer.filter';
import { ContractFilter } from './contract.filter';
import { RequestFilter } from './request.filter';

export class Filter {

    consumer: ConsumerFilter;
    address: AddressFilter;
    request: RequestFilter;
    contract: ContractFilter;

    name: String;
    result: String;
    save: boolean;

    constructor() {
        this.consumer = new ConsumerFilter();
        this.address = new AddressFilter();
        this.request = new RequestFilter();
        this.contract = new ContractFilter();

        this.result = 'GRID';
    }
}
