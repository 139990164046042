import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common'
import { Router } from '@angular/router';
import { ChartType } from '../shared/chart/chart.component';
import * as legend from 'chartist-plugin-legend';

import { Home } from '../Home';
import { ReportFixedService } from './service/report-fixed.service';


@Component({
  selector: 'app-report-fixed',
  templateUrl: './report-fixed.component.html',
  styleUrls: ['./report-fixed.component.css']
})
export class ReportFixedComponent extends Home implements OnInit {

  lista: any;
  rpt: any;
  result: boolean;
  chartview: boolean;
  loading: any;
  dtgenerate: any;

  //
  public activityChartType: ChartType;
  public activityChartData: any;
  public activityChartOptions: any;
  public activityChartResponsive: any[];

  constructor(private router: Router, private reportfixedService: ReportFixedService, public datepipe: DatePipe) {
    super(router);
  }

  ngOnInit(): void {
    this.load();
  }

  goBack() {
    this.result = !this.result;
    this.chartview = false;
    this.loading = undefined;
    this.rpt = undefined;
  }

  goChart() {
    this.chartview = !this.chartview;
  }

  load() {
    const socket = this.reportfixedService.get()
      .subscribe(succ => {
        const ret: any = succ;
        this.lista = ret;
      }, err => {
        this.lista = [];
      }, () => {
        socket.unsubscribe();
      });
  }

  getReport(id) {
    this.loading = id;
    const socket = this.reportfixedService.getId(id + '/report')
      .subscribe(succ => {
        const ret: any = succ;
        this.rpt = ret;
        this.result = !this.result;
        this.loading = undefined;
        this.dtgenerate = this.datepipe.transform(Date.now(), 'dd-MM-yyyy HH:mm:ss');
        this.loadChat(this.rpt);
      }, err => {
        this.lista = [];
      }, () => {
        socket.unsubscribe();
      });
  }

  doReport(rpt) {
    console.log(rpt);
    const socket = this.reportfixedService.doDownload(rpt.id + '/excel')
      .subscribe(succ => {
        const resp: any = succ;

        let name = 'relatorio_' + rpt.report.toLocaleLowerCase();
        name = 'excel_' + name + '_' + Date.now() + '.xlsx';

        const a = document.createElement('a');
        const blob = new Blob([resp]);

        a.href = URL.createObjectURL(blob);
        a.download = name;
        a.target = '_blank';
        a.click();

        a.remove();
      }, err => {
        console.log('err', err);
      }, () => {
        socket.unsubscribe();
      });
  }

  sumMonth(lst) {
    let ret = 0;
    lst.forEach(e => {
      ret += e;
    });
    return ret;
  }

  loadChat(data) {

    const series = [];
    const legends = [];

    data.data.forEach(i => {
      const s = [];
      data.header.forEach(y => {
        s.push(this.sumMonth(i[y]));
      });

      series.push(s);
      legends.push(i.term);

    });

    this.chart({
      labels: data.header,
      series: series
    },
      legends);
  }

  chart(data, legends) {
    this.activityChartType = ChartType.Line;
    this.activityChartData = data;
    this.activityChartOptions = {
      axisX: {
        showGrid: false
      },
      height: '245px',
      plugins: [
        legend({
          legendNames: legends,
          position: 'bottom'
        })
      ]
    };
    this.activityChartResponsive = [
      ['screen and (max-width: 100%)', {
        seriesBarDistance: 5,
        axisX: {
          labelInterpolationFnc: function (value) {
            return value[0];
          }
        }
      }]
    ];
  }
}
