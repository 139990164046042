import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NgxMaskModule, IConfig } from 'ngx-mask';

import { ConsumerContractComponent } from './consumer-contract.component';
import { ConsumerContract } from './model/consumer-contract.model';
import { ConsumerContractService } from './service/consumer-contract.service';
import { Contract } from './model/contract.model';

export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

@NgModule({
  declarations: [
    ConsumerContractComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaskModule.forRoot()
  ],
  providers: [Contract, ConsumerContract, ConsumerContractService]
})
export class ConsumerContractModule { }
