import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ReportFixedComponent } from './report-fixed.component';
import { ReportFixedService } from './service/report-fixed.service';
import { ChartModule } from '../shared/chart/chart.module';

@NgModule({
    declarations: [
        ReportFixedComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ChartModule
    ],
    providers: [ReportFixedService]
})
export class ReportFixedModule { }
