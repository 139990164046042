import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { RequestService } from './service/request.service';
import { RequestComponent } from './request.component';
import { Solicitation } from './model/solicitation.model';


@NgModule({
    declarations: [
        RequestComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule
    ],
    providers: [RequestService, Solicitation]
})
export class RequestModule { }
