// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular-cli.json`.


// ng build --prod

export const environment = {
  production: false,
  api: {
    credential: 'authorization',
    auth: 'https://api.supercadastro.com.br/v1/auth',
    home: 'https://api.supercadastro.com.br/v1/',
    prefix: 'SP/São Paulo/'
  },
  maps: 'AIzaSyDJbzBtOApwHboImqglmuAN4yP_KboOyS4',
  report: {
    classification_client: '6dc3941f-0e18-41d8-9c4f-234723c33a44',
    status_request: 'be59dcdd-fb10-472a-b90d-4e9330b2a3e9',
    neighborhood: '8cd5dced-cae3-4bd8-9de8-8368b97f899f',
    classification_request: 'b686d254-2a94-49bc-91b8-27d77453a596',
    genre: '1feb4aba-bad2-4145-a74b-4a62f219740e',
    register: 'd7201753-d809-4e57-876f-8a741bcde9a5',
    user: '10a23db2-f56b-4e02-8e05-d18e2599f7f2',
    age: 'fd06987d-def9-4c00-a9b6-944d1b3a6765'
  }
}
