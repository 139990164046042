import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ViacepService {

  api: string;
  prefix: string;

  constructor(private http: HttpClient) {
    this.api = 'https://viacep.com.br/ws/';
    this.prefix = environment.api.prefix;
  }

  getCEP(data, model): any {
    const socket = this.http.get(this.api + data + '/json')
      .subscribe(succ => {
        const ret: any = succ;
        model.loadCep(ret);
      }, err => {
        console.log('err', err);
      }, () => {
        socket.unsubscribe();
      });
  }

  getAddress(data) {
    return this.http.get(this.api + this.prefix + data + '/json');
  }
}
