import { Routes } from '@angular/router';

import { HomeComponent } from './home.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { SearchComponent } from './search/search.component';
import { RequestComponent } from './request/request.component';
import { ReportComponent } from './report/report.component';
import { MapsComponent } from './maps/maps.component';
import { ClassificationComponent } from './classification/classification.component';
import { UserComponent } from './user/user.component';
import { ParameterComponent } from './parameter/parameter.component';
import { ProfileComponent } from './profile/profile.component';
import { ConsumerComponent } from './consumer/consumer.component';
import { ConsumerRequestComponent } from './consumer-request/consumer-request.component';
import { ReportFixedComponent } from './report-fixed/report-fixed.component';
import { CalendarComponent } from './shared/calendar/calendar.component';
import { ConsumerContractComponent } from './consumer-contract/consumer-contract.component';
import { ContractComponent } from './contract/contract.component';

export const HomeRoutes: Routes = [{
    path: 'home',
    component: HomeComponent,
    children: [
        { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
        { path: 'dashboard', component: DashboardComponent },
        { path: 'search', component: SearchComponent },
        { path: 'request', component: RequestComponent },
        { path: 'contract', component: ContractComponent },
        { path: 'report', component: ReportComponent },
        { path: 'report-fixed', component: ReportFixedComponent },
        { path: 'maps', component: MapsComponent },
        { path: 'maps/:id', component: MapsComponent },
        { path: 'classification', component: ClassificationComponent },
        { path: 'user', component: UserComponent },
        { path: 'parameter', component: ParameterComponent },
        { path: 'profile', component: ProfileComponent },
        { path: 'consumer', component: ConsumerComponent },
        { path: 'consumer/:id', component: ConsumerComponent },
        { path: 'consumer-request/:id', component: ConsumerRequestComponent },
        { path: 'consumer-contract/:id', component: ConsumerContractComponent },
        { path: 'calendar', component: CalendarComponent },
    ]
}];
