import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NguiMapModule } from '@ngui/map';

import { MapsComponent } from './maps.component';
import { environment } from '../../../environments/environment';
import { MapsService } from './service/maps.service';

@NgModule({
    declarations: [
        MapsComponent
    ],
    imports: [
        CommonModule,
        NguiMapModule.forRoot({ apiUrl: 'https://maps.google.com/maps/api/js?key=' + environment.maps })
    ],
    providers: [MapsService]
})
export class MapModule { }
