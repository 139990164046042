import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { Home } from '../Home';
import { Contract } from './model/contract.model';
import { ContractService } from './service/contract.service';

@Component({
  selector: 'app-contract',
  templateUrl: './contract.component.html',
  styleUrls: ['./contract.component.css']
})
export class ContractComponent extends Home implements OnInit {

  rmodal: boolean
  list: any;
  listflt: any;
  liststt: any;
  classifications: any;
  btn: boolean;
  pbtn: boolean;
  types: any;

  constructor(private router: Router, private contractService: ContractService, public model: Contract) {
    super(router);
    this.rmodal = false;
    this.liststt = [];
  }

  ngOnInit(): void {
    this.load();
    this.loadTypes();
    this.loadClassification();
    this.model = new Contract();
  }

  load() {
    const socket = this.contractService.get()
      .subscribe(succ => {
        const ret: any = succ;
        this.list = this.toList(ret);
        this.pbtn = false;
      }, err => {
        this.pbtn = false;
      }, () => {
        socket.unsubscribe();
      });
  }

  loadClassification() {
    const socket = this.contractService.dropdown('classification/Contrato')
      .subscribe(succ => {
        const ret: any = succ;
        this.classifications = ret;
      }, err => {
        console.log('err', err);
        this.allowAcess(err.status);
      }, () => {
        socket.unsubscribe();
      });
  }

  loadTypes() {
    this.types = [{ id: 'C', name: 'Contrato' }, { id: 'A', name: 'Aditivo' }];
  }

  doContract(model, valid) {
    if (!valid || this.btn) { return; }
    model.consumerid = this.model.consumer.id;
    this.btn = !this.btn;

    const socket = this.contractService.patch(model.id, model)
      .subscribe(succ => {
        const ret: any = succ;
        this.load();
        this.popup('Contrato atualizada com sucesso!');
        this.visiblemodal();
        this.btn = !this.btn;
      }, err => {
        this.allowAcess(err.status);
        this.popup('Não foi possivel atualizaro contrato!', 'danger');
        console.log('err', err);
        this.btn = !this.btn;
      }, () => {
        socket.unsubscribe();
      });
  }

  doSearch(model, valid) {

    if (this.pbtn) return;
    this.pbtn = !this.pbtn;

    if (!model.number && !model.description) {
      this.listflt = [];
      this.load();
      return;
    }

    const socket = this.contractService.custompost('contract/search', model)
      .subscribe(succ => {
        const ret: any = succ;
        this.list = this.toList(ret);
        this.pbtn = !this.pbtn;
      }, err => {
        this.pbtn = !this.pbtn;
      }, () => {
        socket.unsubscribe();
      });
  }

  openConsumer(item) {
    this.go('home/consumer', item.consumer.id);
  }

  openContract(item) {
    this.visiblemodal();
    this.model.load(item);
  }

  visiblemodal() {
    this.rmodal = !this.rmodal;
    this.model = new Contract();
  }

  toList(list) {
    this.liststt = [];
    this.listflt = [];
    list.forEach(s => {
      if (!this.liststt.find(f => f.status === s.status)) {
        this.liststt.push({
          status: s.status,
          active: false,
          rows: (list.filter(x => x.status === s.status)).length
        });
      }
    });

    if (this.liststt.length === 1) {
      this.toListFilter(list, this.liststt[0].status);
      this.liststt = [];
      return list;
    }

    if (this.liststt.length) {
      this.liststt = this.liststt.sort((a, b) => { if (a.status > b.status) { return 1; } if (a.status < b.status) { return -1; } });
      this.liststt[0].active = true;
      this.toListFilter(list, this.liststt[0].status);
    }

    return list;
  }

  toListFilter(list, status) {

    this.liststt.forEach(s => {
      s.active = s.status === status;
    });

    this.listflt = list.filter(f => f.status === status);
  }
}
