import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { ImageCroppedEvent } from 'ngx-image-cropper';

import { Home } from '../Home';
import { Consumer } from './model/consumer.model';
import { ConsumerService } from './service/consumer.service';
import { ViacepService } from './service/viacep.service';

@Component({
  selector: 'app-consumer',
  templateUrl: './consumer.component.html',
  styleUrls: ['./consumer.component.css']
})
export class ConsumerComponent extends Home implements OnInit {

  paramid: any;
  states: any = [];
  genres: any = [];
  age: any;
  lastupdated: any;
  classifications: any = [];
  listaddress: any = [];
  listname: any = [];
  imgChangeEvt: any;
  cropImgPreview: any;
  pmodal: boolean;
  btn: boolean;

  constructor(private router: Router, private activated: ActivatedRoute, public model: Consumer,
    private consumerService: ConsumerService, private viacep: ViacepService) {
    super(router);
    this.pmodal = false;
  }

  ngOnInit() {
    this.loadState();
    this.loadGenre();
    this.loadClassification();
    this.clear();

    this.paramid = this.activated.snapshot.params.id;
    if (this.paramid) {
      this.loadConsumer(this.paramid);
    }
  }

  loadConsumer(id) {
    const socket = this.consumerService.getId(id)
      .subscribe(succ => {
        const ret: any = succ;
        this.model.load(ret);
        this.lastupdated = ret.updated;
        this.birthToage();
        this.paramid = undefined;
      }, err => {
        console.log('err', err);
      }, () => {
        socket.unsubscribe();
      });
  }

  doConsumer(valid) {

    if (!valid || !this.age || this.btn) { return; }
    this.btn = !this.btn;

    if (!this.model.id) {
      this.doInsert();
    } else {
      this.doUpdate();
    }
  }

  doInsert() {
    const socket = this.consumerService.post(this.model)
      .subscribe(succ => {
        const ret: any = succ;
        this.model.id = ret.id;
        this.model.address.id = ret.ida;
        this.popup('Registro incluido com sucesso!');
        this.btn = !this.btn;
      }, err => {
        this.allowAcess(err.status);
        this.popup(this.consumerService.msg[err.error.code], 'danger');
        console.log('err', err);
        this.btn = !this.btn;
      }, () => {
        socket.unsubscribe();
      });
  }

  doUpdate() {
    const socket = this.consumerService.patch(this.model.id, this.model)
      .subscribe(succ => {
        const ret: any = succ;
        this.popup('Registro atualizado com sucesso!');
        this.btn = !this.btn;
      }, err => {
        this.allowAcess(err.status);
        this.popup(this.consumerService.msg[err.error.code], 'danger');
        console.log('err', err);
        this.btn = !this.btn;
      }, () => {
        socket.unsubscribe();
      });
  }

  loadState() {
    this.states = ['AC', 'AL', 'AP', 'AM',
      'BA', 'CE', 'DF', 'ES', 'GO', 'MA',
      'MT', 'MS', 'MG', 'PA', 'PB', 'PR',
      'PE', 'PI', 'RJ', 'RN', 'RS', 'RO',
      'RR', 'SC', 'SP', 'SE', 'TO'];
  }

  loadGenre() {
    this.genres = [{ id: 'M', name: 'Masculino' }, { id: 'F', name: 'Feminino' }];
  }

  loadClassification() {
    const socket = this.consumerService.dropdown('classification/Cliente')
      .subscribe(succ => {
        const ret: any = succ;
        this.classifications = ret;
      }, err => {
        console.log('err', err);
        this.allowAcess(err.status);
      }, () => {
        socket.unsubscribe();
      });
  }

  birthToage() {
    this.age = this.model.age();
  }

  cep(data) {
    if (data === undefined || data === '') { this.model.addressClear(); }

    if (!data || data.length !== 9) { return; }
    this.viacep.getCEP(data.replace('-', ''), this.model.address);
  }

  findAddress(name) {

    this.listaddress = [];
    this.listname = [];
    if (name === undefined || name.length < 3 || name === '') {
      return;
    }

    name = name.toUpperCase();

    const socket = this.viacep.getAddress(name)
      .subscribe(succ => {
        const ret: any = succ;

        this.listaddress = ret
          .filter((i) => {
            return i.logradouro !== '';
          })
          .sort(function (a, b) {
            if (a.logradouro < b.logradouro) { return -1; }
            if (a.logradouro > b.logradouro) { return 1; }
            return 0;
          })
          .slice(0, 15);
      }, err => {
        console.log('err', err);
      }, () => {
        socket.unsubscribe();
      });
  }

  findName(name) {

    this.listname = [];
    this.listaddress = [];
    if (name === undefined || name.length < 3 || name === '') {
      return;
    }

    const socket = this.consumerService.custompost('consumername', { name: name, birth: this.model.birth })
      .subscribe(succ => {
        const ret: any = succ;
        this.listname = ret;
      }, err => {
        console.log('err', err);
      }, () => {
        socket.unsubscribe();
      });
  }

  selectaddress(address) {
    this.model.address.loadCep(address);
    this.listaddress = [];
    this.listname = [];
  }

  selectname(id) {
    this.loadConsumer(id);
    this.listname = [];
    this.listaddress = [];
  }

  clear() {
    this.model = new Consumer();
    this.birthToage();
    this.listname = [];
    this.listaddress = [];
    this.lastupdated = undefined;
  }

  cleanlistname() {
    this.listname = [];
  }

  cleanlistaddress() {
    this.listaddress = [];
  }

  myrequest(id) {
    if (!id) { return; }
    this.go('./home/consumer-request', id);
  }

  mycontract(id) {
    if (!id) { return; }
    this.go('./home/consumer-contract', id);
  }

  onFileChange(event: any): void {
    this.imgChangeEvt = event;
  }

  cropImg(e: ImageCroppedEvent) {
    this.cropImgPreview = e.base64;
  }

  doCrop() {
    this.visiblemodal();
    this.model.photo = this.cropImgPreview;
  }

  imgLoad() {
    this.pmodal = !this.pmodal;
  }

  changestatus(stt) {
    this.model.status = !stt;
  }

  visiblemodal() {
    this.pmodal = !this.pmodal;
    this.imgChangeEvt = undefined;
  }
}
