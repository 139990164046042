import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Home } from '../Home';

import { Profile } from './model/profile.model';
import { MeService } from './service/me.service';

declare var $: any;

declare interface RouteInfo {
    path: string;
    title: string;
    icon: string;
    class: string;
    admin: boolean
}
export const ROUTES: RouteInfo[] = [
    { path: 'dashboard', title: 'Dashboard', icon: 'pe-7s-graph3', class: '', admin: false },
    { path: 'consumer', title: 'Cliente', icon: 'pe-7s-users', class: '', admin: false },
    { path: 'search', title: 'Consulta', icon: 'pe-7s-search', class: '', admin: false },
    { path: 'request', title: 'Ocorrências', icon: 'pe-7s-chat', class: '', admin: false },
    { path: 'contract', title: 'Contratos', icon: 'pe-7s-folder', class: '', admin: false },
    { path: 'report', title: 'Relatorio', icon: 'pe-7s-copy-file', class: '', admin: false },
    { path: 'report-fixed', title: 'Relatorio Fixo', icon: 'pe-7s-copy-file', class: '', admin: false },
    { path: 'maps', title: 'Mapa', icon: 'pe-7s-map-marker', class: '', admin: false },
    { path: 'classification', title: 'Classificações', icon: 'pe-7s-pin', class: '', admin: false },
    { path: 'user', title: 'Usuarios', icon: 'pe-7s-id', class: '', admin: false },
    // { path: 'parameter', title: 'Parametros', icon: 'pe-7s-tools', class: '', admin: false }
];

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html'
})
export class SidebarComponent extends Home implements OnInit {
    menuItems: any[];

    constructor(public router: Router, private meService: MeService, public model: Profile) {
        super(router);
    }

    ngOnInit() {
        this.loadUser();
        this.listing();

        const isWindows = navigator.platform.indexOf('Win') > -1 ? true : false;
        if (isWindows) {
            // if we are on windows OS we activate the perfectScrollbar function
            $('.sidebar .sidebar-wrapper, .main-panel').perfectScrollbar();
            $('html').addClass('perfect-scrollbar-on');
        } else {
            $('html').addClass('perfect-scrollbar-off');
        }
    }

    listing() {
        this.meService.change.subscribe(updated => {
            if (updated) {
                this.loadUser(updated);
            }
        });
    }

    isMobileMenu() {
        if ($(window).width() > 991) {
            return false;
        }
        return true;
    };

    loadUser(force = false) {
        if (!this.model.name || force) {
            const socket = this.meService.get()
                .subscribe(succ => {
                    const ret: any = succ;
                    this.model = ret;
                    if (!this.model.photo) {
                        this.model.photo = 'assets/img/default-avatar.png';
                    }

                    this.loadMenu();
                }, err => {
                    console.log(err);
                    this.allowAcess(err.status);
                }, () => {
                    socket.unsubscribe();
                });
        }
    }

    loadMenu() {
        this.menuItems = ROUTES.filter(menuItem => !menuItem.admin || menuItem.admin === this.model.profile);
    }

    goProfile() {
        this.go('/home/profile');
    }
}
