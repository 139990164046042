import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HomeService } from 'app/home/home.service';

@Injectable()
export class ClassificationService extends HomeService {

  public msg: any;

  constructor(public http: HttpClient) {
    super(http, 'classification');
    this.loadMessage();
  }

  private loadMessage() {
    this.msg = {
      '400.00': 'Dados inválidos.',
      '400.01': 'Nome é obrigatório.',
      '400.02': 'Tipo é obrigatório.',
      '400.03': 'Situação é obrigatório.',
      '400.04': 'Nome já existe para esse tipo',
      '400.10': 'Dados inválidos.',
      '400.12': 'Identificador inválido.',
      '400.13': 'Tipo inválido.',
      '400.14': 'Situação é obrigatório.',
      '400.15': 'Obrigatório o tipo para alterar o nome.',
      '400.16': 'Nome já existe para esse tipo',
      '400.41': 'Gabiente inválido.'
    }
  }
}
