import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ClassificationComponent } from './classification.component';
import { ClassificationService } from './service/classification.service';
import { Classification } from './model/classification.model';

@NgModule({
    declarations: [
        ClassificationComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule
    ],
    providers: [ClassificationService, Classification]
})
export class ClassificationModule { }
