import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';

import { environment } from '../../../environments/environment';
import { ChangePasswordService } from './service/change-password.service';
import { Change } from './model/change.model';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  btnActive: boolean;
  msg: string;

  constructor(private router: Router,
    private changePasswordService: ChangePasswordService, private activatedRoute: ActivatedRoute,
    public change: Change) {
    this.btnActive = false;
  }

  ngOnInit() {
    this.getParam();
  }

  doChangePassword(model, valid) {

    if (!valid) { return; }
    this.btnActive = !this.btnActive;

    const socket = this.changePasswordService.post(this.change)
      .subscribe(succ => {
        const ret: any = succ;
        this.msg = 'Senha atualizada com sucesso!';
        sessionStorage.setItem(environment.api.credential, ret.body.id);
        this.goHome();

      }, err => {
        this.btnActive = !this.btnActive;
        if (err.status === 400) {
          console.log('err', err);
          this.msg = this.changePasswordService.msg[err.error.code];
          return;
        }
        this.msg = 'Não foi possível realizar a operação';
        console.log('err', err);
      }, () => {
        socket.unsubscribe();
      });
  }

  private getParam() {
    this.activatedRoute.params.subscribe((params: Params) => {
      if (!params['id']) {
        this.goLogin();
      }
      try {
        const data = JSON.parse(atob(params['id']));
        this.change.email = data.email;
        this.change.old = data.password;
      } catch (e) {
        this.goLogin();
      }
    });
  }

  public goLogin() {
    this.router.navigate(['/auth']);
  }

  private goHome() {
    this.router.navigate(['/home']);
  }
}
