import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs/Rx';
import { CalendarDateFormatter, CalendarEvent, CalendarView } from 'angular-calendar';

import { CalendarProvider } from './calendar-provider';
import { CalendarService } from './service/calendar.service';
import { EventModel } from './model/event.model';
import { Home } from 'app/home/Home';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  providers: [
    {
      provide: CalendarDateFormatter,
      useClass: CalendarProvider,
    },
  ],
  styleUrls: ['./calendar.component.css']
})
export class CalendarComponent extends Home implements OnInit {

  @ViewChild('f') form;

  CalendarView: any = CalendarView;
  view: CalendarView;
  viewDate: Date;
  events: Array<CalendarEvent>;
  refresh: Subject<any>;
  activeDayIsOpen: boolean;
  locale: string;

  loading: boolean;
  emodal: boolean;
  rmodal: boolean;
  rmv: any;
  hours: any;

  allevents: any;

  constructor(private router: Router, public model: EventModel, private calendarService: CalendarService) {
    super(router);
  }

  ngOnInit() {
    this.list();
    this.loadHours();
    this.view = CalendarView.Month;
    this.viewDate = new Date();
    this.refresh = new Subject();
    this.emodal = false;
    this.events = new Array<CalendarEvent>();
    this.loading = false;
    this.locale = 'pt-BR';
  }

  list() {
    const socket = this.calendarService.get()
      .subscribe(succ => {
        const ret: any = succ;
        this.allevents = ret;
        this.toCalendar();
      },
        err => {
          console.log(err);
          this.allowAcess(err.status);
        }, () => {
          socket.unsubscribe();
        });
  }

  doEvent(model, valid) {
    if (!valid) {
      return;
    }
    model.initial += ' ' + model.initialhour;
    model.final += ' ' + model.finalhour;
    if (!model.id) {
      this.doCreated(model);
    } else {
      this.doUpdate(model);
    }
  }

  doCreated(data) {
    const socket = this.calendarService.post(data)
      .subscribe(succ => {
        const ret: any = succ;
        this.list();
        this.popup('Evento cadastrado com sucesso.', 'success');
        this.visiblemodal();
      },
        err => {
          this.allowAcess(err.status);
          console.log(err);
          this.popup('Não foi possivel concluir a operação.', 'danger');
        }, () => {
          socket.unsubscribe();
        });
  }

  doUpdate(data) {
    const socket = this.calendarService.patch(data.id, data)
      .subscribe(succ => {
        const ret: any = succ;
        this.list();
        this.popup('Evento atualizado com sucesso.', 'success');
        this.visiblemodal();
      },
        err => {
          this.allowAcess(err.status);
          console.log(err);
          this.popup('Não foi possivel concluir a operação.', 'danger');
        }, () => {
          socket.unsubscribe();
        });
  }

  doRemove(id) {
    this.loading = true;
    const socket = this.calendarService.del(id)
      .subscribe(succ => {
        const ret: any = succ;
        this.list();
        this.popup('Evento removido com sucesso!');

        this.confirmremove();
        this.visiblemodal();
      },
        err => {
          console.log(err);
          this.allowAcess(err.status);
        }, () => {
          this.loading = false;
          socket.unsubscribe();
        });
  }

  visiblemodal() {
    this.emodal = !this.emodal;
    if (!this.emodal) {
      this.model = new EventModel();
    }
  }

  confirmremove(item = undefined) {
    this.rmv = item;
    this.rmodal = !this.rmodal;
  }

  toCalendar() {
    this.events = new Array<CalendarEvent>();
    this.allevents.forEach(e => {
      this.events.push({
        start: new Date(e.initial),
        end: e.initial === e.final ? undefined : new Date(e.final),
        title: e.name,
        meta: { id: e.id, owner: e.owner },
        color: e.private ? { primary: '#00FF00', secondary: '#00FF00' } : undefined
      });
    });
    this.refresh.next();
    this.dayIsOpen();
  }

  dayClicked(data) {
    this.model.initial = data.date.setHours(1, 0, 0, 0);
    this.model.final = data.date.setHours(1, 0, 0, 0)

    this.visiblemodal();
  }

  handleEvent(event: CalendarEvent) {
    const socket = this.calendarService.getId(event.meta.id)
      .subscribe(succ => {
        const ret: any = succ;
        this.model = ret;
        this.visiblemodal();
      }, err => {

      }, () => {
        socket.unsubscribe();
      });
  }

  loadHours() {
    this.hours = [];
    for (let h = 0; h < 24; h++) {
      for (let m = 0; m < 2; m++) {
        this.hours.push((h < 10 ? '0' + h : h) + ':' + (m ? '30' : '00') + ':' + '00');
      }
    }
  }

  dayIsOpen() {
    const dt = new Date();
    this.events.forEach(e => {
      if (!this.activeDayIsOpen) {
        this.activeDayIsOpen = (e.start.getDate() === dt.getDate()
          && e.start.getMonth() === dt.getMonth()
          && e.start.getFullYear() === dt.getFullYear());
      }
    });
  }
}
