export class Profile {
    public id: string;
    public name: string;
    public phone: string;
    public email: string;
    public photo: string;

    constructor() {
    }
}
