import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { Home } from '../Home';
import { Filter } from './model/filter.model';
import { SearchService } from './service/search.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent extends Home implements OnInit {

  listname: any = [];
  list: any;
  page: any;

  constructor(private router: Router, public model: Filter, private searchService: SearchService) {
    super(router);
  }

  ngOnInit(): void {
    this.model = new Filter();
    this.page = 1;
    this.list = undefined;
    this.doSearch(1, true);
  }

  doSearch(page, valid) {

    if (!valid) { return; }

    if (page === 1) {
      this.list = undefined;
      this.page = 1;
    }

    const socket = this.searchService.search(page, this.model)
      .subscribe(succ => {
        const ret: any = succ;
        this.list = this.toList(ret);
        this.calculepage(ret);
      }, err => {
        this.list = [];
      }, () => {
        socket.unsubscribe();
      });

  }

  toList(lst) {

    if (!this.list) {
      this.list = [];
    }

    lst.forEach(i => {
      if (!i.photo) {
        i.photo = 'assets/img/default-avatar.png';
      }
    });

    return this.list.concat(lst);
  }

  cleanlistname() {
    this.listname = [];
  }

  findName(name) {

    this.listname = [];
    if (name === undefined || name.length < 3 || name === '') {
      return;
    }

    const socket = this.searchService.custompost('consumername', { name: name, birth: this.model.birth })
      .subscribe(succ => {
        const ret: any = succ;
        this.listname = ret;
      }, err => {
        console.log('err', err);
      }, () => {
        socket.unsubscribe();
      });
  }

  goConsumer(id) {
    this.go('home/consumer', id);
  }

  calculepage(list) {

    if (!list || !list.length) {
      this.page = 1;
      return;
    }

    const max = Math.ceil(list[0].size / 10);

    if (this.page < max) {
      this.page = this.page + 1;
    } else {
      this.page = 1;
    }
  }
}
