import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';

import { AuthComponent } from './auth.component';

import { LoginComponent } from './login/login.component';
import { LoginService } from './login/service/login.service';
import { User } from './login/model/user.model';

import { ForgotComponent } from './forgot/forgot.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { AuthRoutes } from './auth.routing';
import { ForgotService } from './forgot/service/forgot.service';
import { Change } from './change-password/model/change.model';
import { ChangePasswordService } from './change-password/service/change-password.service';

@NgModule({
  imports: [
    RouterModule.forChild(AuthRoutes),
    CommonModule,
    HttpClientModule,
    FormsModule
  ],
  declarations: [
    AuthComponent,
    LoginComponent,
    ForgotComponent,
    ChangePasswordComponent
  ],
  exports: [
    AuthComponent
  ],
  providers: [LoginService,
    User,
    ForgotService,
    Change,
    ChangePasswordService]
})
export class AuthModule {
}
