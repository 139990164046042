export class Parameter {
    key: string;
    value: string;
    label: string;
    description: string;

    constructor() {

    }
}
