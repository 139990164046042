import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DashboardComponent } from './dashboard.component';
import { DashboardService } from './service/dashboard.service';
import { ChartModule } from '../shared/chart/chart.module';
import { CalendarSharedModule } from '../shared/calendar/calendar-shared.module';


@NgModule({
    declarations: [
        DashboardComponent
    ],
    imports: [
        CommonModule,
        ChartModule,
        CalendarSharedModule
    ],
    providers: [DashboardService]
})
export class DashboardModule { }
