import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ConsumerRequestComponent } from './consumer-request.component';
import { ConsumerRequest } from './model/consumer-request.model';
import { ConsumerRequestService } from './service/consumer-request.service';
import { Solicitation } from './model/solicitation.model';


@NgModule({
    declarations: [
        ConsumerRequestComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule
    ],
    providers: [Solicitation, ConsumerRequest, ConsumerRequestService]
})
export class ConsumerRequestModule { }
