import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HomeService } from 'app/home/home.service';

@Injectable()
export class DashboardService extends HomeService {

  public msg: any;

  constructor(public http: HttpClient) {
    super(http, 'consumer');
    this.loadMessage();
  }

  private loadMessage() {
    this.msg = { }
  }
}
