export class ConsumerContract {

    id: string;
    name: string;
    birth: string;
    photo: string;
    status: boolean;
    updated: string;

    contracts: any;

    constructor() {
        this.contracts = []
    }

    load(data) {
        this.id = data.id;
        this.name = data.name;
        this.photo = data.photo;
        this.birth = data.birth;
        this.status = data.status;
        this.updated = data.updated;
        this.contracts = data.contracts;

        if (!this.photo) {
            this.photo = 'assets/img/default-avatar.png';
        }
    }

    age() {
        if (this.birth && this.birth.length === 10) {

            const sbirth = this.birth.split('/');
            const nbirth = new Date(parseInt(sbirth[2], 10), parseInt(sbirth[1], 10) - 1, parseInt(sbirth[0], 10));

            const years = new Date().getFullYear() - nbirth.getFullYear();
            const month = new Date().getMonth() - nbirth.getMonth();
            const days = new Date().getDate() - nbirth.getDate();

            if (!years) { return undefined; }

            if (month > 0) { return years; }

            if (month < 0) { return years - 1; }

            if (month === 0 && days <= 0) { return years; }

            return years - 1;
        }
        return undefined;
    }
}
