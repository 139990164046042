import { Component, OnInit, ElementRef } from '@angular/core';
import { Router } from '@angular/router';

import { ROUTES } from '../../sidebar/sidebar.component';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { environment } from 'environments/environment';

@Component({
    // moduleId: module.id,
    selector: 'app-navbar-cmp',
    templateUrl: 'navbar.component.html'
})

export class NavbarComponent implements OnInit {
    private listTitles: any[];
    location: Location;
    private toggleButton: any;
    private sidebarVisible: boolean;

    constructor(location: Location, private element: ElementRef, private router: Router) {
        this.location = location;
        this.sidebarVisible = false;
    }

    ngOnInit() {
        this.listTitles = ROUTES.filter(listTitle => listTitle);
        const navbar: HTMLElement = this.element.nativeElement;
        this.toggleButton = navbar.getElementsByClassName('navbar-toggle')[0];
    }
    sidebarOpen() {
        const toggleButton = this.toggleButton;
        const body = document.getElementsByTagName('body')[0];
        setTimeout(function () {
            toggleButton.classList.add('toggled');
        }, 500);
        body.classList.add('nav-open');

        this.sidebarVisible = true;
    };
    sidebarClose() {
        const body = document.getElementsByTagName('body')[0];
        this.toggleButton.classList.remove('toggled');
        this.sidebarVisible = false;
        body.classList.remove('nav-open');
    };
    sidebarToggle() {
        // const toggleButton = this.toggleButton;
        // const body = document.getElementsByTagName('body')[0];
        if (this.sidebarVisible === false) {
            this.sidebarOpen();
        } else {
            this.sidebarClose();
        }
    };

    getTitle() {
        const titlee = this.location.prepareExternalUrl(this.location.path());
        const path = titlee.split('/').filter(i => i);

        for (let item = 0; item < this.listTitles.length; item++) {
            if (path[2] && '/' + this.listTitles[item].path === '/' + path[2]) {
                return this.listTitles[item].title;
            }
        }
        return '';
    }

    doLogout() {
        sessionStorage.removeItem(environment.api.credential);
        sessionStorage.clear();
        this.router.navigate(['/auth']);
    }
}
