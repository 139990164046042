import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { Home } from '../Home';

import { Parameter } from './model/parameter.model';
import { ParameterService } from './service/parameter.service';

@Component({
  selector: 'app-parameter',
  templateUrl: './parameter.component.html',
  styleUrls: ['./parameter.component.css']
})
export class ParameterComponent extends Home implements OnInit {

  lista: any;

  constructor(private router: Router, private parameterService: ParameterService, public model: Parameter) {
    super(router);
  }

  ngOnInit() {
    this.load();
  }

  load() {
    const socket = this.parameterService.get()
      .subscribe(succ => {
        const ret: any = succ;
        this.lista = ret;
      }, err => {
        console.log(err);
        this.allowAcess(err.status);
        this.popup(this.parameterService.msg[err.error.code], 'danger');
      }, () => {
        socket.unsubscribe();
      });
  }

  doUpdate(key, value) {

    if (value === '' || value === undefined) { return; }

    const socket = this.parameterService.patch(key, { value: value })
      .subscribe(succ => {
        const ret: any = succ;
        this.popup('Parametro atualizado para ' + value + ' !');
      }, err => {
        console.log(err);
        this.allowAcess(err.status);
        this.popup(this.parameterService.msg[err.error.code], 'danger');
      }, () => {
        socket.unsubscribe();
      });
  }
}
