import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { LoginService } from './service/login.service';
import { User } from './model/user.model';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  btnEntrar: boolean;
  msg: string;

  constructor(private loginService: LoginService, public model: User, private router: Router) {
    this.msg = '';
  }

  ngOnInit() {
    this.btnEntrar = false;
    if (sessionStorage.getItem(environment.api.credential)) {
      this.router.navigate(['/home/']);
    }
  }

  doLogin(model, valid) {

    if (!valid || this.btnEntrar) { return; }
    this.btnEntrar = !this.btnEntrar;

    const socket = this.loginService.post(model)
      .subscribe(succ => {
        const ret: any = succ;

        if (ret.status === 202) {
          sessionStorage.setItem(environment.api.credential, ret.body.id);
          this.model.email = '';
          this.model.password = '';
          this.goHome();
        }

        if (ret.status === 205) {
          const id = btoa(JSON.stringify(model));
          this.goChange(id);
        }
      }, err => {
        this.btnEntrar = !this.btnEntrar;
        if (err.status === 400) {
          this.model.email = '';
          this.model.password = '';
          this.msg = this.loginService.msg[err.error.code];
          return;
        }
        console.log('err', err);
        this.msg = 'Erro ao realizar login!';
      }, () => {
        socket.unsubscribe();
      });
  }

  public goFogot() {
    this.router.navigate(['/auth/forgot']);
  }

  private goChange(id) {
    this.router.navigate(['/auth/change', id]);
  }

  private goHome() {
    this.router.navigate(['/home']);
  }
}
