import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ContractComponent } from './contract.component';
import { ContractService } from './service/contract.service';
import { Contract } from './model/contract.model';


@NgModule({
  declarations: [
    ContractComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule
  ],
  providers: [ContractService, Contract]
})
export class ContractModule { }
