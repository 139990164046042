import { Address } from './address.model';

export class Consumer {

    id: string;
    birth: string;
    name: string;
    email: string;
    phone: string;
    cell: string;
    genre: string;
    photo: string;
    message: string;
    status: boolean;

    classifications: any;

    place: string;
    long: string;
    lati: string;

    address: Address;

    addressClear() {
        this.address = new Address();
    }

    age() {
        if (this.birth && this.birth.length === 10) {

            const sbirth = this.birth.split('/');
            const nbirth = new Date(parseInt(sbirth[2], 10), parseInt(sbirth[1], 10) - 1, parseInt(sbirth[0], 10));

            const years = new Date().getFullYear() - nbirth.getFullYear();
            const month = new Date().getMonth() - nbirth.getMonth();
            const days = new Date().getDate() - nbirth.getDate();

            if (!years) { return undefined; }

            if (month > 0) { return years; }

            if (month < 0) { return years - 1; }

            if (month === 0 && days <= 0) { return years; }

            return years - 1;
        }

        return undefined;
    }

    load(data) {
        this.id = data.id;
        this.birth = data.birth;
        this.name = data.name;
        this.email = data.email;
        this.phone = data.phone;
        this.cell = data.cell;
        this.genre = data.genre;
        this.photo = data.photo;
        this.message = data.message;
        this.place = data.place;
        this.long = data.log;
        this.lati = data.lati;

        this.status = data.status;

        this.classifications = data.classifications;
        this.address.load(data.address);
        if (!this.photo) {
            this.photo = 'assets/img/default-avatar.png';
        }
    }

    constructor() {
        this.address = new Address();
        this.photo = 'assets/img/default-avatar.png';
        this.status = true;
    }
}
