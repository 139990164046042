export class ConsumerFilter {

    birthday: string;
    initial: string;
    final: string;
    genre: string;
    name: string;
    email: string;
    phone: string;
    cell: string;
    status: boolean;

    classifications: any;

    constructor() {
        this.status = true;
    }
}
