export class Change {
    public email: string;
    public old: string;
    public password: string;
    public confirm: string;

    constructor() {

    }
}
