import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HomeService } from 'app/home/home.service';

@Injectable()
export class UserService extends HomeService {

  public msg: any;

  constructor(public http: HttpClient) {
    super(http, 'user');
    this.loadMessage();
  }

  private loadMessage() {
    this.msg = {
      '400.01': 'E-mail é obrigatório.',
      '400.02': 'Senha é obrigatório.',
      '400.03': 'E-mail ou senha é obrigatório.',
      '400.10': 'Dados inválidos.',
      '400.11': 'E-mail é obrigatório.',
      '400.12': 'Telefone é obrigatório.',
      '400.13': 'Situação é obrigatório.',
      '400.14': 'E-mail já existe.',
      '400.20': 'Dados inválidos.',
      '400.21': 'Identificador é obrigatório.',
      '400.22': 'Identificador é inválido.',
      '400.23': 'E-mail já existe.',
      '400.30': 'Dados inválidos.',
      '400.31': 'E-mail é obrigatório.',
      '400.32': 'Senha antiga é obrigatório.',
      '400.33': 'Senha nova é obrigatório.',
      '400.34': 'Senha nova inválida.',
      '400.35': 'E-mail ou senha são inválidos.'
    }
  }
}
