export class EventModel {
    public id: string;
    public name: string;
    public description: string;
    public initial: Date;
    public initialhour: String;
    public final: Date;
    public finalhour: String;
    public private: boolean;
    public priority: boolean;
    public owner: boolean;

    constructor() {
    }
}
