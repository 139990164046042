import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { ParameterComponent } from './parameter.component';
import { ParameterService } from './service/parameter.service';
import { Parameter } from './model/parameter.model';

@NgModule({
    declarations: [
        ParameterComponent
    ],
    imports: [
        CommonModule,
        FormsModule
    ],
    providers: [ParameterService, Parameter]
})
export class ParameterModule { }
