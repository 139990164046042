import { Injectable, Output, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HomeService } from 'app/home/home.service';

@Injectable()
export class MeService extends HomeService {

  reloadMe: boolean;
  @Output() change: EventEmitter<boolean> = new EventEmitter();

  constructor(public http: HttpClient) {
    super(http, 'profile');
    this.reloadMe = false;
  }

  updated(status) {
    this.reloadMe = status;
    this.change.emit(this.reloadMe);
    this.reloadMe = false;
  }
}
