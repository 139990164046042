import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HomeService } from 'app/home/home.service';

@Injectable({
  providedIn: 'root'
})
export class ReportService extends HomeService {

  constructor(private http: HttpClient) {
    super(http, 'report');
   }
}
