import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from 'app/auth/auth.service';

@Injectable()
export class ChangePasswordService extends AuthService {

  public msg: any;

  constructor(private http_: HttpClient) {
    super(http_, '/change');
    this.loadMessage();
  }

  private loadMessage() {
    this.msg = {
      '400.00': 'Dados obrigátorio.',
      '400.1': 'E-mail é campo obrigatorio.',
      '400.2': 'Senha antiga é campo obrigatorio.',
      '400.3': 'Senha nova é campo obrigatorio.',
      '400.4': 'Senha nova é igual antiga.',
      '400.12': 'E-mail ou senha inválido.'
    }
  }
}
