import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HomeService } from 'app/home/home.service';

@Injectable()
export class ReportFixedService extends HomeService {

  public msg: any;

  constructor(public http: HttpClient) {
    super(http, 'reportfixed');
    this.loadMessage();
  }


  public doDownload(url) {
    this.load();

    const opt = Object.assign({}, this.options);
    opt['responseType'] = 'blob';
    opt['observe'] = 'body';

    return this._http.get(this.api + '/' + url, opt);
  }

  private loadMessage() {
    this.msg = {
      '400.00': 'Dados inválidos.',
      '400.01': 'Nome é obrigatório.'
    }
  }
}
