import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { NgxMaskModule, IConfig } from 'ngx-mask';
import { ImageCropperModule } from 'ngx-image-cropper';

import { ProfileComponent } from './profile.component';
import { ProfileService } from './service/profile.service';
import { Profile } from './model/profile.model';
import { Change } from './model/change.model';
import { MeService } from '../sidebar/service/me.service';

export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

@NgModule({
    declarations: [
        ProfileComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        NgxMaskModule.forRoot(),
        ImageCropperModule
    ],
    providers: [ProfileService, MeService, Profile, Change]
})
export class ProfileModule { }
