import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { HomeService } from 'app/home/home.service';

@Injectable({
  providedIn: 'root'
})
export class ConsumerContractService extends HomeService {

  public msg: any;

  constructor(private http: HttpClient) {
    super(http, 'contract');
    this.loadMessage();
  }

  private loadMessage() {
    this.msg = {
      '400.00': 'Dados inválidos.',
      '400.01': 'Protocolo é obrigatório.',
      '400.02': 'Descrição é obrigatória.',
      '400.03': 'Situação é obrigatório.',
      '400.04': 'Cliente é obrigatório.',
      '400.05': 'Cliente inválido.',
      '400.06': 'Protocolo inválido',
      '400.10': 'Dados inválidos.',
      '400.11': 'Protocolo é obrigatório.',
      '400.12': 'Descrição é obrigatória.',
      '400.13': 'Situação é obrigatório.',
      '400.14': 'Cliente inválido.',
      '400.15': 'Ocorrência inválida.',
      '400.20': 'Identificador é obrigatório.',
      '400.21': 'Token é obrigatório.'
    }
  }
}
