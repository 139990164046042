import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { AuthService } from 'app/auth/auth.service';

@Injectable()
export class ForgotService extends AuthService {

  public msg: any;

  constructor(private http_: HttpClient) {
    super(http_, '/forgot');
    this.loadMessage();
  }

  private loadMessage() {
    this.msg = {
      '400.00': 'Parametro inválido.',
      '400.1': 'E-mail é campo obrigatorio.',
      '400.12': 'E-mail inválido.'
    }
  }
}
