import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HomeService } from 'app/home/home.service';

@Injectable()
export class SearchService extends HomeService {

  public msg: any;

  constructor(public http: HttpClient) {
    super(http, 'consumer');
    this.loadMessage();
  }

  private loadMessage() {
    this.msg = {
      '400.00': 'Dados inválidos.',
      '400.01': 'Nome é obrigatório.',
      '400.02': 'Gênero é obrigatório.',
      '400.03': 'Data de nascimento  é obrigatório.',
      '400.10': 'Dados inválidos.',
      '400.11': 'Nome é obrigatório',
      '400.20': 'Gabinete inválido.',
      '400.30': 'Gabinete inválido.',
      '400.31': 'Nome inválido.',
      '400.40': 'identificador inválido.',
      '400.41': 'Gabinete inválido.'
    }
  }
}
