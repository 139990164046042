import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NgxMaskModule, IConfig } from 'ngx-mask';

import { ReportComponent } from './report.component';
import { Filter } from './model/filter.model';
import { ReportService } from './service/report.service';
import { ConsumerFilter } from './model/consumer.filter';
import { AddressFilter } from './model/address.filter';
import { RequestFilter } from './model/request.filter';
import { ContractFilter } from './model/contract.filter';

export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

@NgModule({
    declarations: [
        ReportComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NgxMaskModule.forRoot()
    ],
    providers: [Filter, ConsumerFilter, AddressFilter, RequestFilter, ReportService, ContractFilter]
})
export class ReportModule { }
