import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { UserComponent } from './user.component';
import { User } from './model/user.model';
import { UserService } from './service/user.service';
import { NgxMaskModule } from 'ngx-mask';

@NgModule({
    declarations: [
        UserComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NgxMaskModule
    ],
    providers: [UserService, User]
})
export class UserModule { }
