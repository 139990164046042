import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule, registerLocaleData } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import localePt from '@angular/common/locales/pt';

import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { CalendarComponent } from './calendar.component';
import { EventModel } from './model/event.model';
import { CalendarService } from './service/calendar.service';

registerLocaleData(localePt);
export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

@NgModule({
    declarations: [
        CalendarComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        NgxMaskModule.forRoot(),
        BrowserAnimationsModule,
        CalendarModule.forRoot({
            provide: DateAdapter,
            useFactory: adapterFactory,
        }),
    ],
    exports: [
        CalendarComponent
    ],
    providers: [EventModel, CalendarService]
})
export class CalendarSharedModule { }
