import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HomeService } from 'app/home/home.service';

@Injectable()
export class CalendarService extends HomeService {

  constructor(public http: HttpClient) {
    super(http, 'event');
  }
}
