import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';

import { Home } from '../Home';
import { MapsService } from './service/maps.service';



@Component({
  selector: 'app-maps',
  templateUrl: './maps.component.html',
  styleUrls: ['./maps.component.css']
})
export class MapsComponent extends Home implements OnInit {

  addresses: any;

  constructor(private router: Router, private activatedRoute: ActivatedRoute, private mapsService: MapsService) {
    super(router);
  }

  private getParam() {
    this.activatedRoute.params.subscribe((params: Params) => {
      const ret = sessionStorage.getItem(params['id']);
      if (ret) {
        this.toAddresses(JSON.parse(ret));
        sessionStorage.removeItem(params['id']);
      } else {
        this.getAll();
      }
    });
  }

  ngOnInit(): void {
    this.getParam();
  }

  goConsumer(event) {
    console.log('goConsumer', event);
  }

  toAddresses(lst) {

    this.addresses = [];
    lst.forEach(i => {
      this.addresses.push({
        address: i.address,
        position: { lat: parseFloat(i.lat), lng: parseFloat(i.lng) }
      });
    });
  }

  getAll() {
    const socket = this.mapsService.post({})
      .subscribe(succ => {
        const ret: any = succ;
        this.toAddresses(ret);
      },
        err => {
          if (err.status === 404) { return; }
          console.log('err', err);
          this.allowAcess(err.status);
        }, () => {
          socket.unsubscribe();
        });
  }
}
