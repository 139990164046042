import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { Home } from '../Home';
import { Solicitation } from './model/solicitation.model';
import { RequestService } from './service/request.service';

@Component({
  selector: 'app-request',
  templateUrl: './request.component.html',
  styleUrls: ['./request.component.css']
})
export class RequestComponent extends Home implements OnInit {

  rmodal: boolean
  list: any;
  listflt: any;
  liststt: any;
  classifications: any;
  btn: boolean;

  constructor(private router: Router, private requestService: RequestService, public model: Solicitation) {
    super(router);
    this.rmodal = false;
    this.liststt = [];
  }

  ngOnInit(): void {
    this.load();
    this.loadClassification();
    this.model = new Solicitation();
  }

  load() {
    const socket = this.requestService.get()
      .subscribe(succ => {
        const ret: any = succ;
        this.list = this.toList(ret);
      }, err => {

      }, () => {
        socket.unsubscribe();
      });
  }

  loadClassification() {
    const socket = this.requestService.dropdown('classification/Ocorrência')
      .subscribe(succ => {
        const ret: any = succ;
        this.classifications = ret;
      }, err => {
        console.log('err', err);
        this.allowAcess(err.status);
      }, () => {
        socket.unsubscribe();
      });
  }

  doRequest(model, valid) {
    if (!valid || this.btn) { return; }
    model.consumerid = this.model.consumer.id;
    this.btn = !this.btn;

    const socket = this.requestService.patch(model.id, model)
      .subscribe(succ => {
        const ret: any = succ;
        this.load();
        this.popup('Ocorrência atualizada com sucesso!');
        this.visiblemodal();
        this.btn = !this.btn;
      }, err => {
        this.allowAcess(err.status);
        this.popup(this.requestService.msg[err.error.code], 'danger');
        console.log('err', err);
        this.btn = !this.btn;
      }, () => {
        socket.unsubscribe();
      });
  }

  doSearch(model, valid) {

    if (!model.protocol && !model.description) {
      this.listflt = [];
      this.load();
      return;
    }

    const socket = this.requestService.custompost('request/search', model)
      .subscribe(succ => {
        const ret: any = succ;
        this.list = this.toList(ret);
      }, err => {

      }, () => {
        socket.unsubscribe();
      });
  }

  openConsumer(item) {
    this.go('home/consumer', item.consumer.id);
  }

  openRequest(item) {
    this.visiblemodal();
    this.model.load(item);
  }

  visiblemodal() {
    this.rmodal = !this.rmodal;
    this.model = new Solicitation();
  }

  toList(list) {
    this.liststt = [];
    this.listflt = [];
    list.forEach(s => {
      if (!this.liststt.find(f => f.status === s.status)) {
        this.liststt.push({
          status: s.status,
          active: false,
          rows: (list.filter(x => x.status === s.status)).length
        });
      }
    });

    if (this.liststt.length === 1) {
      this.toListFilter(list, this.liststt[0].status);
      this.liststt = [];
      return list;
    }

    if (this.liststt.length) {
      this.liststt = this.liststt.sort((a, b) => { if (a.status > b.status) { return 1; } if (a.status < b.status) { return -1; } });
      this.liststt[0].active = true;
      this.toListFilter(list, this.liststt[0].status);
    }

    return list;
  }

  toListFilter(list, status) {

    this.liststt.forEach(s => {
      s.active = s.status === status;
    });

    this.listflt = list.filter(f => f.status === status);
  }
}
