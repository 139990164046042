import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { Home } from '../Home';
import { User } from './model/user.model';
import { UserService } from './service/user.service';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})
export class UserComponent extends Home implements OnInit {

  lista: any;
  umodal: boolean;

  constructor(private router: Router, private userService: UserService,
    public model: User) {
    super(router);
    this.umodal = false;
  }

  ngOnInit(): void {
    this.load();
  }

  load() {
    this.lista = undefined;
    const socket = this.userService.get()
      .subscribe(succ => {
        const ret: any = succ;
        this.lista = this.toConvert(ret);
      },
        err => {
          this.lista = [];
          console.log('err', err);
          this.allowAcess(err.status);
        }, () => {
          socket.unsubscribe();
        });
  }

  toConvert(list) {
    return list;
  }

  doRegiter(model, valid) {
    if (!valid) { return; }

    if (model.id) {
      this.doUpdate(model);
    } else {
      this.doInsert(model);
    }
  }

  doInsert(model) {
    const socket = this.userService.post(model)
      .subscribe(succ => {
        const ret: any = succ;
        this.popup('Usuario adicionado!');
        this.load();
        this.visiblemodal();
      }, err => {
        console.log('err', err);
        this.allowAcess(err.status);
      }, () => {
        socket.unsubscribe();
      })
  }

  doUpdate(model) {
    const socket = this.userService.patch(model.id, model)
      .subscribe(succ => {
        const ret: any = succ;
        this.popup('Usuario atualizada!');
        this.load();
        this.visiblemodal();
      }, err => {
        console.log('err', err);
        this.allowAcess(err.status);
      }, () => {
        socket.unsubscribe();
      })
  }

  loadItem(model) {
    this.visiblemodal();
    this.model.id = model.id;
    this.model.name = model.name;
    this.model.email = model.email;
    this.model.phone = model.phone;
    this.model.status = model.status;
  }

  visiblemodal() {
    this.umodal = !this.umodal;
    this.model = new User();
  }
}
