import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { pipe, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import * as CryptoJS from 'crypto-js';
import { totp } from '@otplib/preset-browser';

@Injectable()
export class HomeService {

  public endpoint: string = environment.api.home;
  public options: any;
  public api: string;
  private credential: string = environment.api.credential;

  constructor(public _http: HttpClient, public resource: string) {
    this.load();
  }

  private header() {
    if (!this.options) {
      this.options = { responseType: 'text' };
    }

    const token = sessionStorage.getItem(this.credential);
    if (token) {
      this.options.headers = new HttpHeaders().set(this.credential, token);
    }
  }

  public load() {
    this.header();
    this.api = this.endpoint + this.resource;
  }

  private add(api, id) {
    return id ? api + '/' + id : api;
  }

  public get() {
    this.load();
    return this._http.get(this.api, this.options)
      .pipe(map((res: any) => {
        return res ? this.decrypt(res, this.ntoken()) : res;

      }),
        catchError((err) => {
          if (err && err.error) {
            err.error = this.decrypt(err.error, this.ntoken());
          }
          return throwError(err);
        }));
  }

  public search(page, data) {
    this.load();

    this.api += '/search/' + page;
    data = this.encrypt(data, this.ntoken());
    return this._http.post(this.api, data, this.options)
      .pipe(map((res: any) => {
        return res ? this.decrypt(res, this.ntoken()) : res;

      }),
        catchError((err) => {
          if (err && err.error) {
            err.error = this.decrypt(err.error, this.ntoken());
          }
          return throwError(err);
        }));
  }

  public getId(id) {
    this.load();
    return this._http.get(this.add(this.api, id), this.options)
      .pipe(map((res: any) => {
        return res ? this.decrypt(res, this.ntoken()) : res;

      }),
        catchError((err) => {
          if (err && err.error) {
            err.error = this.decrypt(err.error, this.ntoken());
          }
          return throwError(err);
        }));
  }

  public dashboard(obj) {
    this.load();
    return this._http.get(this.api.replace(this.resource, obj) + '/dashboard', this.options)
      .pipe(map((res: any) => {
        return res ? this.decrypt(res, this.ntoken()) : res;

      }),
        catchError((err) => {
          if (err && err.error) {
            err.error = this.decrypt(err.error, this.ntoken());
          }
          return throwError(err);
        }));
  }

  public post(data) {
    this.load();
    data = this.encrypt(data, this.ntoken());
    return this._http.post(this.api, data, this.options)
      .pipe(map((res: any) => {
        return res ? this.decrypt(res, this.ntoken()) : res;

      }),
        catchError((err) => {
          if (err && err.error) {
            err.error = this.decrypt(err.error, this.ntoken());
          }
          return throwError(err);
        }));
  }

  public custompost(resource, data) {
    this.load();
    data = this.encrypt(data, this.ntoken());
    return this._http.post(this.api.replace(this.resource, resource), data, this.options)
      .pipe(map((res: any) => {
        return res ? this.decrypt(res, this.ntoken()) : res;
      }),
        catchError((err) => {
          if (err && err.error) {
            err.error = this.decrypt(err.error, this.ntoken());
          }
          return throwError(err);
        }));
  }

  public customget(resource) {
    this.load();
    return this._http.get(this.api.replace(this.resource, resource), this.options)
      .pipe(map((res: any) => {
        return res ? this.decrypt(res, this.ntoken()) : res;
      }),
        catchError((err) => {
          if (err && err.error) {
            err.error = this.decrypt(err.error, this.ntoken());
          }
          return throwError(err);
        }));
  }

  public patch(id, data) {
    this.load();
    data = this.encrypt(data, this.ntoken());
    return this._http.patch(this.add(this.api, id), data, this.options)
      .pipe(map((res: any) => {
        return res ? this.decrypt(res, this.ntoken()) : res;
      }),
        catchError((err) => {
          if (err && err.error) {
            err.error = this.decrypt(err.error, this.ntoken());
          }
          return throwError(err);
        }));
  }

  public del(id) {
    this.load();
    return this._http.delete(this.add(this.api, id), this.options)
      .pipe(map((res: any) => {
        return res ? this.decrypt(res, this.ntoken()) : res;
      }),
        catchError((err) => {
          if (err && err.error) {
            err.error = this.decrypt(err.error, this.ntoken());
          }
          return throwError(err);
        }));
  }

  public dropdown(obj) {
    this.load();
    return this._http.get(this.api.replace(this.resource, obj) + '/dropdown', this.options)
      .pipe(map((res: any) => {
        return res ? this.decrypt(res, this.ntoken()) : res;
      }),
        catchError((err) => {
          if (err && err.error) {
            err.error = this.decrypt(err.error, this.ntoken());
          }
          return throwError(err);
        }));
  }

  public download(data, url) {
    this.load();

    this.options.responseType = 'blob';
    this.options.observe = 'body';

    data = this.encrypt(data, this.ntoken());
    return this._http.post(this.api + '/' + url, data, this.options);
  }

  encrypt(data, token = '') {
    const iv = CryptoJS.enc.Utf8.parse((token + '0x1234567890abcdefghij0123456789').slice(0, 16));
    const key = CryptoJS.enc.Utf8.parse((token + '0x1234567890abcdefghij0123456789').slice(0, 32));

    const ret = CryptoJS.AES.encrypt(JSON.stringify(data), key, { iv: iv, mode: CryptoJS.mode.CBC });
    return ret.ciphertext.toString(CryptoJS.enc.Hex);
  }

  private decrypt(data, token = '') {
    let result = undefined;
    const iv = CryptoJS.enc.Utf8.parse((token + '0x1234567890abcdefghij0123456789').slice(0, 16));
    const key = CryptoJS.enc.Utf8.parse((token + '0x1234567890abcdefghij0123456789').slice(0, 32));

    const ret = CryptoJS.AES.decrypt(data, key, { iv: iv, format: CryptoJS.format.Hex, mode: CryptoJS.mode.CBC });

    try {
      result = JSON.parse(ret.toString(CryptoJS.enc.Utf8));
    } catch (e) {
      try {
        result = ret.toString(CryptoJS.enc.Utf8);
      } catch (ex) {
        result = undefined;
      }
    }
    return result;
  }

  private ntoken(seed = 'JBSWY3DPEHPK3PXP') {
    totp.options = {
      epoch: Date.now(),
      step: 30
    };

    return totp.generate(seed);
  }
}
