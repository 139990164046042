import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { Home } from '../Home';
import { Classification } from './model/classification.model';
import { ClassificationService } from './service/classification.service';

@Component({
  selector: 'app-classification',
  templateUrl: './classification.component.html',
  styleUrls: ['./classification.component.css']
})
export class ClassificationComponent extends Home implements OnInit {

  cmodal: boolean;
  lista: any;
  combo: any;
  painel: boolean;

  constructor(private router: Router, private classificationService: ClassificationService,
    public model: Classification) {
    super(router);
    this.cmodal = false;
    this.painel = false;
  }

  ngOnInit(): void {
    this.load();
  }

  load() {
    this.lista = undefined;
    const socket = this.classificationService.get()
      .subscribe(succ => {
        const ret: any = succ;
        this.lista = this.toConvert(ret);
      },
        err => {
          this.lista = [];
          console.log('err', err);
          this.allowAcess(err.status);
        }, () => {
          socket.unsubscribe();
        });
  }

  loadType() {
    const socket = this.classificationService.dropdown('classificationtype')
      .subscribe(succ => {
        const ret: any = succ;
        this.combo = this.toConvert(ret);
      }, err => {
        this.combo = [];
        console.log('err', err);
        this.allowAcess(err.status);
      }, () => {
        socket.unsubscribe();
      });
  }

  toConvert(data) {
    return data;
  }

  doRegiter(model, valid) {
    if (!valid) { return; }

    if (model.id) {
      this.doUpdate(model);
    } else {
      this.doInsert(model);
    }
  }

  doInsert(model) {
    const socket = this.classificationService.post(model)
      .subscribe(succ => {
        const ret: any = succ;
        this.popup('Classificação cadastrada!');
        this.load();
        this.visiblemodal();
      }, err => {
        console.log('err', err);
        this.allowAcess(err.status);
        this.popup(this.classificationService.msg[err.error.code], 'danger');
      }, () => {
        socket.unsubscribe();
      })
  }

  doUpdate(model) {
    const socket = this.classificationService.patch(model.id, model)
      .subscribe(succ => {
        const ret: any = succ;
        this.popup('Classificação atualizada!');
        this.load();
        this.visiblemodal();
      }, err => {
        console.log('err', err);
        this.allowAcess(err.status);
        this.popup(this.classificationService.msg[err.error.code], 'danger');
      }, () => {
        socket.unsubscribe();
      });
  }

  loadItem(model) {
    this.visiblemodal();
    this.model.load(model);
  }

  visiblemodal() {
    this.cmodal = !this.cmodal;
    this.model = new Classification();

    if (this.cmodal) {
      this.loadType();
    }
  }
}
