import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ChartType } from '../shared/chart/chart.component';
import * as legend from 'chartist-plugin-legend';

import { Home } from '../Home';
import { DashboardService } from './service/dashboard.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent extends Home implements OnInit {

  consumer: any;
  classification: any;
  request: any;
  contract: any;
  births: any;

  public activityChartType: ChartType;
  public activityChartData: any;
  public activityChartOptions: any;
  public activityChartResponsive: any[];

  constructor(private router: Router, private dashboardService: DashboardService) {
    super(router)
  }

  ngOnInit(): void {
    this.dashConsumer();
    this.dashClassification();
    this.dashRequest();
    this.dashContract();

    this.loadConsumer();
    this.loadBirth();
  }

  dashConsumer() {
    const socket = this.dashboardService.dashboard('consumer/all')
      .subscribe(succ => {
        const ret: any = succ;
        this.consumer = ret.count;
      }, err => {
        this.consumer = undefined;
        console.log('err', err);
      }, () => {
        socket.unsubscribe();
      });
  }

  dashClassification() {
    const socket = this.dashboardService.dashboard('classification/all')
      .subscribe(succ => {
        const ret: any = succ;
        this.classification = ret.count;
      }, err => {
        this.classification = undefined;
        console.log('err', err);
      }, () => {
        socket.unsubscribe();
      });
  }

  dashRequest() {
    const socket = this.dashboardService.dashboard('request/all')
      .subscribe(succ => {
        const ret: any = succ;
        this.request = ret.count;
      }, err => {
        this.request = undefined;
        console.log('err', err);
      }, () => {
        socket.unsubscribe();
      });
  }

  dashContract() {
    const socket = this.dashboardService.dashboard('contract/all')
      .subscribe(succ => {
        const ret: any = succ;
        this.contract = ret.count;
      }, err => {
        this.contract = undefined;
        console.log('err', err);
      }, () => {
        socket.unsubscribe();
      });
  }

  goConsumer(id) {
    this.go('home/consumer', id);
  }

  chart(data, legends) {

    this.activityChartType = ChartType.Line;
    this.activityChartData = data;
    this.activityChartOptions = {
      seriesBarDistance: 10,
      axisX: {
        showGrid: false
      },
      height: '245px',
      plugins: [
        legend({
          legendNames: legends,
          position: 'bottom'
        })
      ]
    };
    this.activityChartResponsive = [
      ['screen and (max-width: 640px)', {
        seriesBarDistance: 5,
        axisX: {
          labelInterpolationFnc: function (value) {
            return value[0];
          }
        }
      }]
    ];
  }

  loadChat(data) {
    const series = [];
    const legends = data.header;
    data.header.forEach(h => {
      series.push(data.data[0][h]);
    });
    this.chart({
      labels: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
      series: series
    }, legends);
  }

  loadConsumer() {
    const reportid = this.env().report.register;
    const socket = this.dashboardService.customget('reportfixed/' + reportid + '/report')
      .subscribe(succ => {
        const ret: any = succ;
        this.loadChat(ret);
      }, err => {
        console.log('err', err);
      }, () => {
        socket.unsubscribe();
      });
  }

  loadBirth() {

    const socket = this.dashboardService.customget('birth')
      .subscribe(succ => {
        const ret: any = succ;
        this.births = ret;
      }, err => {
        this.consumer = undefined;
        console.log('err', err);
      }, () => {
        socket.unsubscribe();
      });

  }

}
