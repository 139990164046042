export class User {
    id: string;
    name: string;
    email: string;
    phone: string;
    status: boolean;

    constructor() {
        this.status = true;
    }
}
