import { Routes } from '@angular/router';

import { LoginComponent } from './login/login.component';
import { ForgotComponent } from './forgot/forgot.component';
import { ChangePasswordComponent } from './change-password/change-password.component';

import { AuthComponent } from './auth.component';

export const AuthRoutes: Routes = [{
    path: 'auth',
    component: AuthComponent,
    children: [{
        path: '',
        redirectTo: 'login',
        pathMatch: 'full'
    }, {
        path: 'login',
        component: LoginComponent
    }, {
        path: 'forgot',
        component: ForgotComponent
    }, {
        path: 'change/:id',
        component: ChangePasswordComponent
    }]
}];
