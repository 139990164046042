export class Classification {
    public id: string;
    public name: string;
    public status: boolean;
    public classificationtypeid: string;

    constructor() {
        this.status = true;
    }

    load(data) {
        this.id = data.id;
        this.name = data.name;
        this.status = data.status;
        this.classificationtypeid = data.classificationtype.id;
    }
}
