import { Consumer } from "./consumer.model";

export class Solicitation {

    public id: string;
    public protocol: string;
    public description: string;
    public classifications: any;
    public updated: string;
    public status: string;
    public consumer: Consumer;

    constructor() {
        this.consumer = new Consumer();
    }

    load(model) {

        this.id = model.id;
        this.protocol = model.protocol;
        this.description = model.description;
        this.status = model.status;

        this.consumer = model.consumer;

        this.classifications = [];
        model.classifications.forEach(e => {
            this.classifications.push(e.id);
        });
    }
}
