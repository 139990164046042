import { Router } from '@angular/router';
import { environment } from 'environments/environment';

declare var $: any;

export class Home {

  private orderby: boolean;

  constructor(private _router: Router) {
    this.orderby = false;
  }

  allowAcess(status) {
    if (status === 401) {
      sessionStorage.removeItem(environment.api.credential);
      this.go('/auth');
    }
  }

  go(location, id = undefined) {

    if (!id) {
      this._router.navigate([location]);
    } else if (id) {
      this._router.navigate([location, id]);
    }
  }

  orderBy(field, list) {

    this.orderby = !this.orderby;

    list = list.sort((obj1, obj2) => {
      if (obj1[field] > obj2[field]) {
        return this.orderby ? 1 : -1;
      }
      if (obj1[field] < obj2[field]) {
        return this.orderby ? -1 : 1;
      }
      return 0;
    });
  }
  // 'info','success','warning','danger'
  popup(msg, t = 'success') {

    if (!msg) { return; }

    $.notify({
      icon: 'pe-7s-info',
      message: msg
    }, {
      type: t,
      timer: 1000,
      placement: {
        from: 'top',
        align: 'right'
      }
    });
  }

  env() {
    return environment;
  }
}
